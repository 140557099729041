import { defineStore } from "pinia";
import { $fetch } from "ofetch";
import {
	getAuth,
	getIdTokenResult,
	signInWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	type User,
} from "firebase/auth";

 
export const useAuthUserStore = defineStore("useAuthUserStore", () => {
	const fb = useFireClient();
	const auth = fb.auth
	const cUser = useState<IUser | undefined>("authUser_state", ()=>undefined)
	const mRoles = ref<string[]>([])
	const router = useRouter()
	//let { user, setUser, setTenantKey, setRoles, setNull } = userState();
	const mError = ref();

	//if(!auth) throw new Error("Auth not initialized is userAuthStore")
		// if(localStorage.getItem("authUser_manual")){
		// 	cUser.value = JSON.parse(localStorage.getItem("authUser_manual") as string)
		// }
		// watch(cUser, (state)=>{
		// 	if(cUser.value?.email !== cUser.value?.email){
		// 		localStorage.setItem("authUser_manual", JSON.stringify(state))
		// 	}else if(cUser.value === undefined){
		// 		localStorage.removeItem("authUser_manual")
		// 	}
		// }, {deep:true})
 
		//const user = computed<IUser | undefined>(() => cUser.value);
		const roles = computed(() => mRoles.value);
		const error = computed(()=> mError.value)
		const isLoggedIn = computed(() => cUser.value !== undefined);
		const isDbbsUser = computed(() => mRoles.value.includes("isDbbs"));
		const isDboUser = computed(() => mRoles.value.includes("isDbo"));
		const isDbsUser = computed(() => mRoles.value.includes("isDbs"));
		const isUploaderUser = computed(() => mRoles.value.includes("isUploader"));
		const isClientHqUser = computed(() => mRoles.value.includes("isClientHq"));
		const isClientSupervisorUser = computed(() => mRoles.value.includes("ClientSupervisor"));
		const isAdmin = computed(() => mRoles.value.includes("isDbAdmin"));

    const pageRoleTitle = computed(() => {
			if (isDbsUser.value) return "DBS Supervisor";
			if (isDboUser.value) return "DBO User";
			if (isUploaderUser.value) return "Uploader";
			if (isClientHqUser.value) return "Client HQ";
			if (isClientSupervisorUser.value) return "Supervisor";
			if (isAdmin.value) return "DBBS Administrator";
		})


		const signin = async (email: string, password: string) => {
			try {
				mError.value = "";
				const userCredential = await signInWithEmailAndPassword(auth,	email, password	);
				if(userCredential.user === null) throw new Error("User not found")

				//retrive user info from server
				 
			  if(userCredential.user.email){

					const userOb = await  $fetch("/api/authuser", {
						method: "POST",
						body: JSON.stringify({ uid: userCredential.user.email }),
					})
					console.log("reutnr user with roles", userOb)
					mRoles.value = [...userOb.roles]
					cUser.value = {...userOb}
					
				 
			}else{
				throw new Error("Not a valid user id " + userCredential.user.uid)
			}
				
				//navigateToHome()
			} catch (err) {
				mError.value = { message: err };
			}
	
			return cUser;
		};
	
		const signout = async () => {
			await auth.signOut();
			cUser.value = undefined
			mError.value = ""			
			mRoles.value = []
			// setNull();
			navigateTo("/auth/login");
		};
		// const getUseInfo = async (uid: string) => {
		// 	console.log("getUserInfo sending uid", uid);
		// 	const userSnap = await $fetch("/api/authuser", {
		// 		method: "POST",
		// 		body: { uid: uid },
		// 	});		
		// 		return userSnap
		// };

		onAuthStateChanged(auth, async (activeUser) => {
	 
			if (activeUser) {
			
				const userOb = await  $fetch("/api/authuser", {
					method: "POST",
					body: JSON.stringify({ uid: activeUser.email }),
				})
				mRoles.value = [...userOb.roles]
				cUser.value = {...userOb}
				console.log("reutnr user with roles", userOb)
				console.log("onAuthStateChanged: user logged in", activeUser.email);
			} else {
				// setNull();
				// token.value = null;
				//signOut(auth)
				console.log("onAuthStateChanged: user logged out");
			}
		});
		const getRoles = () => {
			if (!cUser.value ) return [];
			return mRoles.value;
		};
		const getEmail = () =>{
			if(!auth.currentUser) return ""
			return auth.currentUser.email
		}
		const navigateToHome = ()=>{
			const mRoles = getRoles();
			if (mRoles.length === 0) return;
			const targetPath = userPagesForRole()[0];
			console.log("Navigation path, ", targetPath);
			router.push(targetPath)
		}
		const userPagesForRole = ()=> {
			const userPages = [];
			userPages.length = 0;
			if (mRoles.value.includes("isClientHq")) {
				userPages.push("/client/hq");
				userPages.push("/client/hqreports");
			}
			if (mRoles.value.includes("ClientSupervisor")) {
				userPages.push("/client/review");
				userPages.push("/client/details");
				userPages.push("/client/uploadhistory");
				userPages.push("/client/review");
				userPages.push("/dbbs/ProcessDocs");
				userPages.push("/dbbs/dbsarea/document");
				userPages.push("/dbbs/dbsarea/resolution");
			}
			if (mRoles.value.includes("isDbAdmin")) {
				userPages.push("/dbbs/admin");
				userPages.push("/dbbs/ad/setup");
				userPages.push("/dbbs/ad/team");
				userPages.push("/dbbs/ad/content");
				userPages.push("/dbbs/ad/tenant");
				userPages.push("/dbbs/ad/reports");
				userPages.push("/dbbs/ad/locations");
				userPages.push("/dbbs/ad/assignments");
				userPages.push("/dbbs/ProcessDocs");
				userPages.push("/dbbs/ad/fileuploads");
				userPages.push("/dbbs/ad/useraccounts");
				userPages.push("/dbbs/dbsarea/approve");
				userPages.push("/client/hq");
			}
		
			if (mRoles.value.includes("isDbo")) {
				userPages.push("/dbbs/dbo");
				userPages.push("/dbbs/dboarea/processing");
				userPages.push("/dbbs/ResolutionCenter");
				userPages.push("/dbbs/ProcessDocs");
				userPages.push("/dbbs/dbsarea/resolution");
				userPages.push("/dbbs/dbsarea/document");
				userPages.push("/dbbs/dboarea/dboreports");
			}
			if (mRoles.value.includes("isDbs")) {
				userPages.push("/dbbs/dbs");
				userPages.push("/dbbs/dbsarea/assignWork");
				userPages.push("/dbbs/dbsarea/resolution");
				userPages.push("/dbbs/dbsarea/approve");
				userPages.push("/dbbs/dbsarea/document");
			}
			if (mRoles.value.includes("isGuest")) {
				userPages.push("/client/guest");
			}
			if (mRoles.value.includes("isUploader")) {
				userPages.push("/client/location");
				userPages.push("/client/uploads");
			}
			return userPages;
		}

	return {
		 user: cUser,
		 roles,
		 error,
		 isLoggedIn,
		 isDbbsUser,
		 isDboUser,
		 isDbsUser,
		 isUploaderUser,
		 isClientHqUser,
		 isClientSupervisorUser,
		 isAdmin,
		 pageRoleTitle,
		 getEmail,
		 signin,
		 signout,
		 getRoles,
		 navigateToHome,
		 userPagesForRole
	};
}, {persist: {storage: persistedState.localStorage}});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAuthUserStore, import.meta.hot));
}
