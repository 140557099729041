import {
	Chart,
	// Title,
	// Tooltip,
	// Legend,
  //   PointElement,
  //   LineElement,
	// BarElement,
	// CategoryScale,
	// LinearScale,
	registerables, 
} from "chart.js";
export default defineNuxtPlugin(() => {
	Chart.register(
		...registerables
		// CategoryScale,
		// LinearScale,
    //     LineElement,
		// BarElement,
    //     PointElement,
		// Title,
		// Tooltip,
		// Legend
	);
});
