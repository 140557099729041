

function userPagesForRole(roles: string[]) {
	const userPages = [];
	userPages.length = 0;
	if (roles.includes("isClientHq")) {
		userPages.push("/client/hq");
		userPages.push("/client/hqreports");
		userPages.push("/client/hqexecutive");
		userPages.push("/dbbs/archives");
	}
	if (roles.includes("ClientSupervisor")) {
		userPages.push("/client/review");
		userPages.push("/client/details");
		userPages.push("/client/uploadhistory");
		userPages.push("/client/review");
		userPages.push("/dbbs/ProcessDocs");
		userPages.push("/dbbs/dbsarea/document");
		userPages.push("/dbbs/dbsarea/docuitem");
		userPages.push("/dbbs/dbsarea/resolution");
		userPages.push("/dbbs/archives");
	}
	if (roles.includes("isDbAdmin")) {
		userPages.push("/dbbs/admin");
		userPages.push("/dbbs/ad/setup");
		userPages.push("/dbbs/ad/team");
		userPages.push("/dbbs/ad/content");
		userPages.push("/dbbs/ad/tenant");
		userPages.push("/dbbs/ad/locations");
		userPages.push("/dbbs/ad/assignments");
		userPages.push("/dbbs/ProcessDocs");
		userPages.push("/dbbs/ad/reports");
		userPages.push("/dbbs/ad/fileuploads");
		userPages.push("/dbbs/ad/useraccounts");
		userPages.push("/dbbs/dbsarea/approve");
		userPages.push("/dbbs/dbsarea/docuitem");
		userPages.push("/client/hq");
		userPages.push("/client/hqreports");
		userPages.push("/client/hqexecutive");
		userPages.push("/dbbs/archives");
	}

	if (roles.includes("isDbo")) {
		userPages.push("/dbbs/dbo");
		userPages.push("/dbbs/dboarea/processing");
		userPages.push("/dbbs/ResolutionCenter");
		userPages.push("/dbbs/ProcessDocs");
		userPages.push("/dbbs/dbsarea/resolution");
		userPages.push("/dbbs/dbsarea/document");
		userPages.push("/dbbs/dbsarea/docuitem");
		userPages.push("/dbbs/dboarea/dboreports");
		userPages.push("/dbbs/archives");
	}
	if (roles.includes("isDbs")) {
		userPages.push("/dbbs/dbs");
		userPages.push("/dbbs/dbsarea/assignWork");
		userPages.push("/dbbs/dbsarea/resolution");
		userPages.push("/dbbs/dbsarea/approve");
		userPages.push("/dbbs/dbsarea/document");
		userPages.push("/dbbs/dbsarea/docuitem");
		userPages.push("/dbbs/archives");
	}
	if (roles.includes("isGuest")) {
		userPages.push("/client/guest");
	}
	if (roles.includes("isUploader")) {
		userPages.push("/client/location");
		userPages.push("/client/uploads");
	}
	return userPages;
}


export default defineNuxtRouteMiddleware(async (to, from) => {
	 const authUser = useAuthUserStore();
	 const {user,  } = storeToRefs(authUser);

	const usrCookie = useCookie("authuser");
	// console.log(useNuxtApp())
	console.log("Client middleware", to.path, from.path, user?.value);
	

	const userIsLoggedIn =  user?.value !== undefined;
	const isUserOnLoginPage = to.path === "/auth/login";
  const isOnHome = to.path === "/";
	const userRoles = user?.value?.roles ?? []
	const userPages = userPagesForRole(userRoles);
  const isOnValidPage =  userPages.includes(to.path)
 // console.log("getRoles", getRoles(), "userPagesForRole", userPagesForRole( authUser.getRoles() ?? []), "to.path", to.path, "isValidPage", isOnValidPage);
 //console.log("isOnValidPage", isOnValidPage);
 //console.log("uesrIsLoggedIn", userIsLoggedIn, "isUserOnLoginPage", isUserOnLoginPage);	
 if(to.path === "/client/training") return
 if(to.path === "/" && !userIsLoggedIn) return navigateTo("/auth/login");
 if(to.path == "/" && userIsLoggedIn) return navigateTo(userPages[0]);
 if(to.path === from.path) return;
  if(userIsLoggedIn && isOnValidPage) return ;
  if(userIsLoggedIn && !isUserOnLoginPage && !isOnValidPage) return navigateTo(userPagesForRole(authUser.getRoles() ?? [])[0]);

 if(userIsLoggedIn && isUserOnLoginPage ){
		const roles = authUser.getRoles() ?? [];
		//console.log("user is authenticated", to.path, "from", from.path, "roles", roles);
		const userPages = userPagesForRole(roles);
		if (userPages.length > 0 ) {
      return navigateTo(userPages[0]);
    }
		//return navigateTo(to.path);
 }
 if(!userIsLoggedIn && !isUserOnLoginPage){
	//console.log("User not logged in, redirecting to login page", to.path, from.path);
   		return navigateTo("/auth/login");
 }
 


});
