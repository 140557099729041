import { default as loginH9aqUAq5VnMeta } from "E:/clients/DBBS/ledgerPro/pages/auth/login.vue?macro=true";
import { default as _91slug_93KhaQd0w6tzMeta } from "E:/clients/DBBS/ledgerPro/pages/blog/[slug].vue?macro=true";
import { default as detailscVtsgwmHydMeta } from "E:/clients/DBBS/ledgerPro/pages/client/details.vue?macro=true";
import { default as hqMK6R2OUQJ3Meta } from "E:/clients/DBBS/ledgerPro/pages/client/hq.vue?macro=true";
import { default as hqexecutiveSoUMvFOWMOMeta } from "E:/clients/DBBS/ledgerPro/pages/client/hqexecutive.vue?macro=true";
import { default as hqreportsURTmTzbzy5Meta } from "E:/clients/DBBS/ledgerPro/pages/client/hqreports.vue?macro=true";
import { default as location4ClMu4oDdrMeta } from "E:/clients/DBBS/ledgerPro/pages/client/location.vue?macro=true";
import { default as review3vtixizsHIMeta } from "E:/clients/DBBS/ledgerPro/pages/client/review.vue?macro=true";
import { default as training7fV7r7d7w8Meta } from "E:/clients/DBBS/ledgerPro/pages/client/training.vue?macro=true";
import { default as uploadhistory2r9TZx4sGYMeta } from "E:/clients/DBBS/ledgerPro/pages/client/uploadhistory.vue?macro=true";
import { default as uploadsEUBEme3KGMMeta } from "E:/clients/DBBS/ledgerPro/pages/client/uploads.vue?macro=true";
import { default as assignmentsKOS9iNHm4tMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/assignments.vue?macro=true";
import { default as contentgpWbpSMOn0Meta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/content.vue?macro=true";
import { default as fileuploadsrjlcTMgZE5Meta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/fileuploads.vue?macro=true";
import { default as locationsZpiwt0osccMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/locations.vue?macro=true";
import { default as reportsX5KmVildbAMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/reports.vue?macro=true";
import { default as setupmg55cQ7URVMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/setup.vue?macro=true";
import { default as teamonF6ruhvu3Meta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/team.vue?macro=true";
import { default as tenantI2RJp9ls7rMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/tenant.vue?macro=true";
import { default as useraccountsdbaYP1TkeZMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ad/useraccounts.vue?macro=true";
import { default as adminoAE4iS10z2Meta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/admin.vue?macro=true";
import { default as archivesJ6cPCk3B7BMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/archives.vue?macro=true";
import { default as dbouUdLapZloaMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dbo.vue?macro=true";
import { default as dboreportsJSAV3l2USbMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dboarea/dboreports.vue?macro=true";
import { default as processingpx9kpbra1iMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dboarea/processing.vue?macro=true";
import { default as dbsT5Wb3UrxoyMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dbs.vue?macro=true";
import { default as approvewJHL7eycs5Meta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/approve.vue?macro=true";
import { default as assignWorkm1pt1n288WMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/assignWork.vue?macro=true";
import { default as docuitemLUiTZx43NeMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/docuitem.vue?macro=true";
import { default as resolutionlA5XXK2sJlMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/resolution.vue?macro=true";
import { default as ProcessDocsuYSiRrt582Meta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ProcessDocs.vue?macro=true";
import { default as ResolutionCenter0NXbsbBxAsMeta } from "E:/clients/DBBS/ledgerPro/pages/dbbs/ResolutionCenter.vue?macro=true";
import { default as indexJuHCy2iL5fMeta } from "E:/clients/DBBS/ledgerPro/pages/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginH9aqUAq5VnMeta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "client-details",
    path: "/client/details",
    meta: detailscVtsgwmHydMeta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/details.vue").then(m => m.default || m)
  },
  {
    name: "client-hq",
    path: "/client/hq",
    meta: hqMK6R2OUQJ3Meta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/hq.vue").then(m => m.default || m)
  },
  {
    name: "client-hqexecutive",
    path: "/client/hqexecutive",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/hqexecutive.vue").then(m => m.default || m)
  },
  {
    name: "client-hqreports",
    path: "/client/hqreports",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/hqreports.vue").then(m => m.default || m)
  },
  {
    name: "client-location",
    path: "/client/location",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/location.vue").then(m => m.default || m)
  },
  {
    name: "client-review",
    path: "/client/review",
    meta: review3vtixizsHIMeta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/review.vue").then(m => m.default || m)
  },
  {
    name: "client-training",
    path: "/client/training",
    meta: training7fV7r7d7w8Meta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/training.vue").then(m => m.default || m)
  },
  {
    name: "client-uploadhistory",
    path: "/client/uploadhistory",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/uploadhistory.vue").then(m => m.default || m)
  },
  {
    name: "client-uploads",
    path: "/client/uploads",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/client/uploads.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-assignments",
    path: "/dbbs/ad/assignments",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/assignments.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-content",
    path: "/dbbs/ad/content",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/content.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-fileuploads",
    path: "/dbbs/ad/fileuploads",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/fileuploads.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-locations",
    path: "/dbbs/ad/locations",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/locations.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-reports",
    path: "/dbbs/ad/reports",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/reports.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-setup",
    path: "/dbbs/ad/setup",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/setup.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-team",
    path: "/dbbs/ad/team",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/team.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-tenant",
    path: "/dbbs/ad/tenant",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/tenant.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ad-useraccounts",
    path: "/dbbs/ad/useraccounts",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ad/useraccounts.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-admin",
    path: "/dbbs/admin",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/admin.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-archives",
    path: "/dbbs/archives",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/archives.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dbo",
    path: "/dbbs/dbo",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dbo.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dboarea-dboreports",
    path: "/dbbs/dboarea/dboreports",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dboarea/dboreports.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dboarea-processing",
    path: "/dbbs/dboarea/processing",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dboarea/processing.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dbs",
    path: "/dbbs/dbs",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dbs.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dbsarea-approve",
    path: "/dbbs/dbsarea/approve",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/approve.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dbsarea-assignWork",
    path: "/dbbs/dbsarea/assignWork",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/assignWork.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dbsarea-docuitem",
    path: "/dbbs/dbsarea/docuitem",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/docuitem.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-dbsarea-resolution",
    path: "/dbbs/dbsarea/resolution",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/dbsarea/resolution.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ProcessDocs",
    path: "/dbbs/ProcessDocs",
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ProcessDocs.vue").then(m => m.default || m)
  },
  {
    name: "dbbs-ResolutionCenter",
    path: "/dbbs/ResolutionCenter",
    meta: ResolutionCenter0NXbsbBxAsMeta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/dbbs/ResolutionCenter.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexJuHCy2iL5fMeta || {},
    component: () => import("E:/clients/DBBS/ledgerPro/pages/index.vue").then(m => m.default || m)
  }
]