<script setup lang="ts">

const pageSetting = useState("pageconfig", ():{title:string, icon:string} => (<{title:string, icon:string, rntPage:string}>{
    title: "Page Name",
    icon: "folder",
    rntPage: "/client/location"
  }))
  pageSetting.value.title = "Default"
</script>
<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<style>
 
</style>